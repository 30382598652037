<template>
  <div class="index">
    <div class="nav1">
      <div class="tit1">盲盒订单</div>
      <div class="tit2">
        <!-- <el-tabs v-model="activeName" @tab-click="tabsHandleClick">
          <el-tab-pane label="全部订单" name="1"></el-tab-pane>
          <el-tab-pane label="普通订单" name="2"></el-tab-pane>
          <el-tab-pane label="拼团订单" name="3"></el-tab-pane>
        </el-tabs>-->
      </div>
    </div>
    <!-- <div class="nav2">
      <el-row :gutter="10">
        <el-col :span="6"><div class="box"></div></el-col>
        <el-col :span="6"><div class="box"></div></el-col>
        <el-col :span="6"><div class="box"></div></el-col>
        <el-col :span="6"><div class="box"></div></el-col>
      </el-row>
    </div>-->
    <div class="nav3">
      <div class="myForm">
        <el-form ref="form" :model="form" label-width="80px">
          <el-row>
            <el-col :span="20">
              <el-form-item label="选择时间：">
                <el-date-picker size="small" v-model="form.time" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="20">
              <el-form-item label="当前状态">
                <el-radio-group
                  @change="changeSearch_status"
                  v-model="form.search_status"
                  size="small"
                >
                  <el-radio-button label="-1">所有</el-radio-button>
                  <el-radio-button label="1">待开箱</el-radio-button>
                  <el-radio-button label="2">待提货</el-radio-button>
                  <el-radio-button label="3">待发货</el-radio-button>
                  <el-radio-button label="4">已发货</el-radio-button>
                  <!-- <el-radio-button label="4">已取消</el-radio-button> -->
                  <!-- <el-radio-button label="3">异常订单</el-radio-button> -->
                  <!-- <el-radio-button label="6">已分解</el-radio-button>
                  <el-radio-button label="7">已置换</el-radio-button> -->
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="20">
              <el-form-item label="用户：">
                <el-select :remote-method="remoteMethod" remote size="small" v-model="form.search_id" filterable
                  placeholder="请选择" clearable>
                  <el-option v-for="item in options" :key="item.user_id" :label="`${item.user_name}(id:${item.user_id})`"
                    :value="item.user_id"></el-option>
                </el-select>
                <!-- <el-input size="small" placeholder="请输入关键字" v-model="addForm"></el-input> -->
                <el-button size="small" type="primary" style="margin-left:20px" @click="onSubmit"
                  icon="el-icon-search">查询</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row>
            <el-col :span="20">
              <el-form-item label="支付方式：">
                <el-radio-group v-model="form.rad2" size="small">
                  <el-radio-button label="全部"></el-radio-button>
                  <el-radio-button label="微信支付"></el-radio-button>
                  <el-radio-button label="支付宝支付"></el-radio-button>
                  <el-radio-button label="余额支付"></el-radio-button>
                  <el-radio-button label="线下支付"></el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="创建时间：">
                <el-date-picker
                  size="small"
                  v-model="form.time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="搜索：">
                <div class="search">
                  <el-input
                    size="small"
                    placeholder="请输入内容"
                    v-model="form.search"
                    class="input-with-select"
                  >
                    <el-select
                      class="left-select"
                      v-model="form.select"
                      slot="prepend"
                      placeholder="请选择"
                    >
                      <el-option label="全部" value="1"></el-option>
                      <el-option label="订单号" value="2"></el-option>
                      <el-option label="UID" value="3"></el-option>
                      <el-option label="用户名称" value="4"></el-option>
                      <el-option label="用户电话" value="5"></el-option>
                      <el-option label="商品名称" value="6"></el-option>
                    </el-select>
                    <el-button
                      @click="onSubmit"
                      slot="append"
                      icon="el-icon-search"
                    ></el-button>
                  </el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>-->
        </el-form>
      </div>

      <div class="myTable">
        <vxe-table :data="tableData">
          <vxe-table-column field="orderId" width="70" title="订单ID"></vxe-table-column>
          <vxe-table-column field="userId" width="60" title="用户ID"></vxe-table-column>
          <vxe-table-column field="orderDetailId" width="70" title="商品ID"></vxe-table-column>
          <vxe-table-column field="productName" width="200" title="商品名称"></vxe-table-column>
          <vxe-table-column field="productThumb" width="100" title="盲盒封面">
            <template slot-scope="scope">
              <div style="display:inline-block;margin:0 3px" v-for="(item, i) in scope.row.productThumb" :key="i">
                <el-image v-if="item.url != ''" :src="item.url" :preview-src-list="[item]" fit="fill"
                  style="width: 40px; height: 40px">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </div>
            </template>
          </vxe-table-column>
          <vxe-table-column field="number" width="70" title="盲盒数量"></vxe-table-column>
          <vxe-table-column field="productPrice" width="70" title="盲盒价格"></vxe-table-column>
          <vxe-table-column field="orderNo" width="160" title="订单号"></vxe-table-column>
          <vxe-table-column field="productAmountTotal" width="70" title="商品总价"></vxe-table-column>
          <vxe-table-column field="paymentTime" width="150" title="付款时间"></vxe-table-column>
          <vxe-table-column field="createTime" width="150" title="下单时间"></vxe-table-column>
          <vxe-table-column field="createdAt" width="150" title="创建时间"></vxe-table-column>
          <vxe-table-column field="updatedAt" width="150" title="修改时间"></vxe-table-column>
          <vxe-table-column field="orderStatusName" width="70" title="开箱状态">
            <template slot-scope="scope">
              <el-tag :type="scope.row.orderStatusName != '待发货' ? 'success' : 'info'" disable-transitions>{{
                scope.row.orderStatusName }}</el-tag>
            </template>
          </vxe-table-column>
          <vxe-table-column field="payChannelName" width="100" title="订单支付渠道"></vxe-table-column>
          <vxe-table-column field="payName" width="70" title="支付方式">
            <template slot-scope="scope">
              <el-tag :type="scope.row.payName === '支付宝' ? 'primary' : 'success'" disable-transitions>{{ scope.row.payName
              }}</el-tag>
            </template>
          </vxe-table-column>
          <vxe-table-column field="payStatus" width="80" title="支付状态">
            <template slot-scope="scope">
              <el-tag :type="scope.row.payStatus === '已支付' ? 'success' : 'warning'" disable-transitions>{{
                scope.row.payStatus }}</el-tag>
            </template>
          </vxe-table-column>
          <vxe-table-column field="outTradeNo" width="160" title="第三方支付流水号"></vxe-table-column>





          <vxe-table-column title="操作状态" width="120" fixed="right">
            <template slot-scope="scope">
              <div class="flex">
                <el-button :disabled="scope.row.orderStatus != '3'" size="small" @click="fahuo(scope.row)"
                  type="text">发货</el-button>
                <el-button size="small" @click="seeXiangqin(scope.row)" type="text">订单详情</el-button>
              </div>
            </template>
          </vxe-table-column>
        </vxe-table>
        <el-pagination class="fenye" @size-change="this.handleSizeChange" @current-change="this.handleCurrentChange"
          :current-page="this.mangheDingdanPage" :page-size="10" :page-sizes="[10, 15, 20, 30]"
          layout="total,sizes, prev, pager, next, jumper" :total="this.total"></el-pagination>
      </div>
    </div>

    <!-- 订单详情 -->
    <el-dialog title="订单详情" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
      <div class="myTable">
        <vxe-table :data="xiangqinTableData">
          <vxe-table-column field="productName" title="盲盒名称"></vxe-table-column>
          <vxe-table-column field="goodsName" title="奖品名称"></vxe-table-column>
          <vxe-table-column field="tag" title="稀有度"></vxe-table-column>
          <vxe-table-column field="orderTotal" title="商品价格"></vxe-table-column>
          <vxe-table-column field="orderStatus" title="订单状态"></vxe-table-column>
        </vxe-table>
        <!-- <el-pagination
          class="fenye"
          @size-change="this.handleSizeChange"
          @current-change="this.handleCurrentChange"
          :current-page="this.yonghuguanliPage"
          :page-size="10"
          :page-sizes="[10, 15, 20, 30]"
          layout="total,sizes, prev, pager, next, jumper"
          :total="this.mingxiTotal"
        ></el-pagination> -->
      </div>

      <!-- <div class="myAddForm">
        <el-form ref="form1" :model="form1" label-width="80px">
          <el-form-item label="盲盒名称">
            <el-input size="small" v-model="form1.productName"></el-input>
          </el-form-item>

         <el-form-item label="盲盒封面"> 
            <div class="myImg">
              <el-image :src="form1.productThumb[0]?form1.productThumb[0].url:''" fit="fill" style="width: 60px; height: 60px">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
          </el-form-item>
  
          <el-form-item label="盲盒价格">
            <el-input size="small" v-model="form1.productPrice" style="width:10%"></el-input>
          </el-form-item>

          <el-form-item label="订单号">
            <el-input size="small" v-model="form1.name" style="width:10%"></el-input>
          </el-form-item>
          <el-form-item label="第三方支付订单号" label-width="80px">
            <el-input size="small" v-model="form1.name" style="width:10%"></el-input>
          </el-form-item>
          <el-form-item label="用户ID">
            <el-select size="small" v-model="form1.region" placeholder="用户ID">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
         
          <el-form-item label="订单状态">
            <el-radio-group size="small" v-model="form1.resource">
              <el-radio label="待开箱"></el-radio>
              <el-radio label="待提货"></el-radio>
              <el-radio label="代发货"></el-radio>
              <el-radio label="已发货"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="onSubmit">立即创建</el-button>
            <el-button size="small">取消</el-button>
          </el-form-item>
        </el-form>
      </div> -->
    </el-dialog>
    <!-- 发货 -->
    <el-dialog title="发货" :visible.sync="fahuoDialogVisible" width="30%" :before-close="fahuoHandleClose" :close-on-click-modal=false>
      <div class="fahuomyForm">
        <el-form :model="fahuoForm" ref="fahuoForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="快递单号">
            <el-input size="small" v-model="fahuoForm.order_no" style="width:50%;"  maxlength="15"></el-input>
          </el-form-item>
          <el-form-item label="快递公司">
            <!-- <el-input size="small" v-model="fahuoForm.company"></el-input> -->
            <el-select size="small" v-model="fahuoForm.express" clearable placeholder="请选择">
              <el-option v-for="item in ExpressListData" :key="item.id" :label="item.itemText" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="运费">
            <el-input size="small" v-model="fahuoForm.freight" style="width:40%;" maxlength="5" >
              <template slot="prepend">¥</template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="submitForm">发货</el-button>
          </el-form-item>
        </el-form>
        <el-form :model="ConsigneeInfoFrom" ref="ConsigneeInfoFrom" label-width="100px" class="demo-ruleForm">
          <hr />
          <br />
          <el-form-item label="收件人信息">
            <el-input type="textarea" size="small" v-model="ConsigneeInfoFrom.consigneeInfo" :readonly=true></el-input>
          </el-form-item>
          <el-form-item label="奖品名称">
            <el-input size="small" v-model="ConsigneeInfoFrom.productName"  :readonly=true></el-input>
          </el-form-item>
          <el-form-item label="奖品图片">
            <!-- <el-input size="small" v-model="ConsigneeInfoFrom.productThumb[0].url"></el-input> -->
            <el-image v-for="(item, index) in ConsigneeInfoFrom.productThumb" :key="index" :src="item.url?item.url:''" :preview-src-list="getPreviewImgList(index)" fit="fill"
              style="width: 60px; height: 60px"  >
              
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </el-form-item>
          <el-form-item label="奖品价格">
            <el-input size="small" v-model="ConsigneeInfoFrom.productPrice"  :readonly=true></el-input>
          </el-form-item>

        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["mangheDingdanPage", "mangheDingdanPageSize"])
  },
  watch: {
    mangheDingdanPage: function (page) {
      this.$store.commit("mangheDingdanPage", page);
      this.getData();
    },
    mangheDingdanPageSize: function (pageSize) {
      this.$store.commit("mangheDingdanPageSize", pageSize);
      this.getData();
    }
  },
  data() {
    return {
      dialogVisible: false,
      activeName: "3",
      options: [],
      form: {
        time: [],
        search_id: ""
        ,search_status:-1
      },
      ExpressListData: {},
      ConsigneeInfoFrom: {},
      form1: {
        createdAt: "",
        number: "",
        orderAmountTotal: "",
        orderDetailId: "",
        orderId: "",
        orderNo: "",
        outTradeNo: "",
        payChannelName: "",
        payName: "",
        payStatus: "",
        paymentTime: "",
        productAmountTotal: "",
        productName: "",
        productPrice: "",
        productThumb: [],
        updatedAt: "",
        userId: 0,
      },
      tableData: [],
      total: 0,
      fahuoId: "",
      fahuoDialogVisible: false,
      fahuoForm: {
        order_no: "",
        express: "",
        freight:0
      },
      box_buy_id: "",
      xiangqinTableData: [],
      rules: {
        express_code: [
          { required: true, message: "请输入快递单号", trigger: "blur" }
        ],
        express_name: [
          { required: true, message: "请输入快递名称", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.getData();
    this.getUserData();
  },
  methods: {
    async getData() {
      const res = await this.$api.searchBoxOrder({
        currentPage: this.mangheDingdanPage,
        size: this.mangheDingdanPageSize,
        user_id: this.form.search_id,
        orderStatus:this.form.search_status,
        start_time: this.form.time ? this.form.time.length > 0 ? this.form.time[0] : "" : "",
        end_time: this.form.time ? this.form.time.length > 0 ? this.form.time[1] : "" : ""
      });
      this.tableData = res.content.records;
      this.total = res.content.total;

      // res.data.data.forEach(ele => {
      //   if (ele.open_time) {
      //     ele.myOpen_time = this.formatDate(ele.open_time);
      //   }
      //   if (ele.box_time) {
      //     ele.myBox_time = this.formatDate(ele.box_time);
      //   }
      // });
      // this.$set(this, "tableData", res.content);
    },
    remoteMethod(query) {
      this.getUserData(query)
      // if (query !== "") {
      //   setTimeout(() => {
      //     this.loading = false;
      //     this.options = this.list.filter(item => {
      //       return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
      //     });
      //   }, 200);
      // } else {
      //   this.options = [];
      // }
    },
    seeXiangqin(row) {
      this.box_buy_id = row.orderId;
      this.form1 = row;
      // this.dialogVisible = true;
      this.getXqData();
    },
    getPreviewImgList: function (index) {
      let arr = [];
      for (let i = 0; i < this.ConsigneeInfoFrom.productThumb.length; i++) {
        arr.push(this.ConsigneeInfoFrom.productThumb[i + index].url);
        if (i + index >= this.ConsigneeInfoFrom.productThumb.length - 1) { // 为了取后边小值（采用绝对值）
          index = 0 - (i + 1);
        }
      }
      return arr;
    },
    async getConsigneeInfo() {
      const res = await this.$api.queryConsigneeInfo({
        orderId: this.box_buy_id
      });
      this.ConsigneeInfoFrom = res.content

    },
    async getXqData() {
      const res = await this.$api.searchBoxOrderDetail({
        orderId: this.box_buy_id
      });
      this.xiangqinTableData = res.content;
      // this.xiangqinTableData.forEach(ele => {
      //   ele.myShop_degree =
      //     ele.shop_degree == "0"
      //       ? "金色传说"
      //       : ele.shop_degree == "1"
      //       ? "红色史诗"
      //       : ele.shop_degree == "2"
      //       ? "紫色稀有"
      //       : "蓝色普通";
      // });
      this.dialogVisible = true;
    }, async getExpressList() {
      const res = await this.$api.queryDictItem({
        dictId: 1
      });
      this.ExpressListData = res.content;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    async getUserData(search_key) {
      const res = await this.$api.getUserList({
        size: 100000,
        currentPage: 1,
        id: search_key
      });
      this.options = res.content.records;
    },
    formatDate(now) {
      var time = new Date(now);
      var year = time.getFullYear();
      var month = time.getMonth() + 1;
      var date = time.getDate();
      var hour = time.getHours();
      var minute = time.getMinutes();
      var second = time.getSeconds();
      return (
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    },
    async submitForm() {
      // 发货
      const res = await this.$api.changeOrderToSended({
        orderId:this.fahuoId,
        expressNo: this.fahuoForm.order_no,
        expressName: this.fahuoForm.express,
        logisticsFee:this.fahuoForm.freight
      });
      if (res.content) {
        this.$message({
          message: "发货成功",
          type: "success"
        });
        this.getData();
        this.fahuoDialogVisible = false;
      } else {
        this.$message.error(res.msg);
      }
    },
    changeSearch_status() {
      this.getData();
    },
    onSubmit() {
      this.getData();
    },
    changeRad1() {
      this.getData();
    },
    changeRad2() {
      this.getData();
    },
    fahuo(row) {
      this.box_buy_id = row.orderId;
      this.getConsigneeInfo(row);
      this.getExpressList();
      this.fahuoId = row.orderId;
      this.fahuoDialogVisible = true;
    },
    async fahuo2(row) {
      this.fahuoId = row.order_id;
      const res = await this.$api.getCardListByOrderid({
        order_id: row.order_id
      });
      if (res.data.length > 0) {
        var card_id = res.data[0].card_id;
        const res2 = await this.$api.changeOrderToSendedByCard({
          order_id: row.order_id,
          card_id
        });
        if (res2.status == 200) {
          this.$message({
            message: res2.msg,
            type: "success"
          });
          this.getData();
        } else {
          this.$message.error(res2.msg);
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    async yichangDingdan(row) {
      const res = await this.$api.changeOrderToError({
        order_id: row.order_id
      });
      if (res.status == 200) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getData();
      }
    },
    async quxiaoDingdan(row, flag) {
      const res = await this.$api.changeOrderToCancle({
        order_id: row.order_id,
        need_refund: flag
      });
      if (res.status == 200) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getData();
      }
    },
    async toEditShop(row) {
      this.$confirm("确认删除？").then(async () => {
        const res = await this.$api.orderDel({
          id: row.id
        });
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success"
          });
          this.getData();
        }
      });
    },
    // tabsHandleClick(tab, event) {
    // },
    fahuoHandleClose() {
      this.fahuoDialogVisible = false;
    },
    // 分页
    handleSizeChange(val) {
      this.$store.commit("mangheDingdanPageSize", val);
    },
    handleCurrentChange(val) {
      this.$store.commit("mangheDingdanPage", val);
    }
  }
};
</script>


<style lang="scss" scoped>
.index {}

.nav1 {
  margin: 0 -18px;
  background-color: #fff;
  padding: 20px 32px 0 40px;

  .tit1 {
    color: #17233d;
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 20px;
  }

  .tit2 {
    margin-top: 10px;

    /deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
      padding-left: 20px;
    }

    /deep/ .el-tabs__header {
      margin: 0 0 1px;
    }
  }
}

.nav2 {
  margin-top: 20px;
  height: 110px;

  .box {
    background: #fff;
    height: 110px;
    box-shadow: 0px 0 4px 2px #dddddd !important;
  }
}

.myImg {
  position: relative;
  width: 60px;
  height: 60px;
  display: inline-block;
  margin-right: 12px;

  .closeBtn {
    position: absolute;
    top: -6px;
    right: -4px;
    width: 20px;
    height: 20px;

    .el-button {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  /deep/ .image-slot {
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fafafa;
    width: 58px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .el-icon-picture-outline {
      font-size: 20px;
    }
  }
}

.nav3 {
  margin: 18px 0;
  background: #fff;
  border-radius: 6px;
  padding: 24px;

  .myForm {
    /deep/ .el-form-item__label {
      font-size: 12px;
    }

    /deep/ .el-form-item {
      margin-right: 30px;
      margin-bottom: 0;
    }

    .search {
      margin-top: 3px;

      /deep/ .el-select {
        width: 100px;
      }
    }
  }

  .tit1 {
    margin-top: 10px;
  }

  .myTable {
    margin-top: 10px;

    .xiala {
      padding: 10px 20px;

      .item {
        font-size: 12px;
      }
    }

    .flex {
      display: flex;
      align-items: center;
    }

    .fenye {
      margin-top: 10px;
    }

    /deep/ .vxe-cell {
      white-space: normal !important;
      overflow: visible !important;
    }

    /deep/ .vxe-table--render-default .vxe-body--column {
      line-height: 14px;
      vertical-align: middle;
    }

    /deep/ .vxe-cell--label {
      font-size: 12px;
    }

    /deep/ .vxe-cell--title {
      font-size: 12px;
    }

    /deep/ .image-slot {
      width: 38px;
      height: 38px;
      border: 1px solid #ddd;
      line-height: 38px;
      text-align: center;
      border-radius: 4px;
    }

    /deep/ .vxe-cell {
      max-height: none;
      padding: 6px 10px;
    }
  }
}

.shopxx {
  display: flex;
  align-items: center;
  margin: 4px 0;

  .shopImg {
    width: 40px;
    height: 40px;
    margin-right: 6px;
  }

  .txt {
    font-size: 12px;
    line-height: 16px;
  }
}

.fahuomyForm {
  /deep/ .el-form-item__label {
    font-size: 12px;
  }

  /deep/ .el-form-item {
    margin-right: 30px;
    margin-bottom: 20px;
  }

  .search {
    margin-top: 3px;

    /deep/ .el-select {
      width: 100px;
    }
  }
}
</style>